import React, { useEffect, useState } from 'react';
import { Input } from '@GDM/forms';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { put, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import getContractTypeLabel from '@utils/contract/getLabel';
import { approve_v2_invoice_insight_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useInvoicesContext } from '../invoicesContext';
import styles from './update-modal.module.scss';
import { UpdateIndexInvoiceForm } from './update-modal.types';
import { UpdateModalProps } from './UpdateModal';

const getFormDefaultValues = (invoice: MeterInvoice | null) => ({
  volume: {
    last_index: invoice?.volume_last_index?.toString() || '',
    last_conso_index: invoice?.volume_last_conso_index?.toString() || '',
  },
});

const ERRORS_MAP: { [field: string]: string } = {
  'cannot be true for approved': 'sales_management.update.errors.forecast_approved',
};

export const UpdateModalIndex: React.FC<UpdateModalProps> = ({ isOpen, toggle, invoice }) => {
  const { t } = useTranslation();
  const [prevVolumeId, setPrevVolumeId] = useState(invoice?.volume_id);
  const reqUpdate = useRequest<MeterInvoice>(approve_v2_invoice_insight_path(invoice?.volume_id || 'none'), put, true);
  const { reset, control, handleSubmit, formState } = useForm<UpdateIndexInvoiceForm>({
    defaultValues: getFormDefaultValues(invoice),
    mode: 'onChange',
  });
  const { updateInvoice } = useInvoicesContext();
  const disabled = !formState.isDirty;

  if (prevVolumeId !== invoice?.volume_id) {
    reset(getFormDefaultValues(invoice));

    setPrevVolumeId(invoice?.volume_id);
  }

  const handleSubmitCallback = (values: UpdateIndexInvoiceForm) => {
    const data = {
      last_index: parseFloat(values.volume.last_index.replace(/,/g, '.')),
      last_conso_index: parseFloat(values.volume.last_conso_index.replace(/,/g, '.')),
    };

    reqUpdate.execute?.(data);
  };

  useEffect(() => {
    if (reqUpdate.data) {
      updateInvoice(reqUpdate.data);
      toggle();
    }
  }, [reqUpdate.data, updateInvoice, toggle]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (!invoice) return null;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      header={t('sales_management.invoices.update_invoice_title', {
        installation_name: invoice.installation_name,
        contract_type: t(getContractTypeLabel(invoice.contract_type, invoice.installation_country)),
        end_date:
          dayjs(invoice.end_date).format('MMM YYYY').charAt(0).toUpperCase() +
          dayjs(invoice.end_date).format('MMM YYYY').slice(1),
      })}
      isLoading={reqUpdate.loading}
      submitAction={handleSubmit(handleSubmitCallback)}
      submitDisabled={disabled}
      saveButtonText="common.approve"
      data-cy="update-invoice-modal"
    >
      <div className={styles['index-layout']}>
        <Controller
          control={control}
          name="volume.last_index"
          render={({ field }) => (
            <Input
              label="sales_management.last_prod_index"
              suffix="kWh"
              full
              {...field}
              data-cy="revenues.last_prod_index"
            />
          )}
        />
        <Controller
          control={control}
          name="volume.last_conso_index"
          render={({ field }) => (
            <Input
              label="sales_management.last_consumption_index"
              suffix="kWh"
              full
              {...field}
              data-cy="revenues.last_consumption_index"
            />
          )}
        />
      </div>

      <Text className="mt-3" text={t('sales_management.approve_warning')} />

      {/* {reqUpdate.error && <Text type="danger" className="mt-3" text={reqUpdate?.error?.message} />} */}
      {reqUpdate.errors?.map((error) => (
        <Text
          type="danger"
          className="mt-3"
          text={error.code ? ERRORS_MAP[error.code] : 'errors.unknown_error'}
          key={error.field}
        />
      ))}
    </Modal>
  );
};
